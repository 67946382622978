<template>
  <NuxtLink
    class="flex h-10 w-10 items-center justify-center rounded-full border border-primary text-primary shadow-[0px_0px_44px_0px_#12E2FF] backdrop-blur transition-transform duration-500 hover:rotate-180"
    :class="[$style.background, isMounted ? 'scale-100' : 'scale-0']"
    @click="navigate ? handleNavigate() : undefined"
  >
    <Icon name="close" :color="theme.colors.primary" />
  </NuxtLink>
</template>

<script setup lang="ts">
import { theme } from '#tailwind-config'

defineProps({
  navigate: {
    type: Boolean,
    default: true
  }
})

const localePath = useLocalePath()
const isMounted = ref(false)
const router = useRouter()
const { history } = router.options

function handleNavigate() {
  const homePath = localePath('/')

  if (history.state.back === localePath('/')) {
    router.back()
  } else {
    navigateTo(homePath)
  }
}

onMounted(() => {
  isMounted.value = true
})
</script>

<style lang="postcss" module>
.background {
  background: linear-gradient(
    90deg,
    rgba(18, 226, 255, 0.3) 0%,
    rgba(18, 226, 255, 0.2) 100%,
    rgba(18, 226, 255, 0) 100%
  );
}
</style>
